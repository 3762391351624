import { useState } from "react";

import logo from "../../assets/logo-min.png";
import { useTranslation } from "react-i18next";

import styles from "./Header.module.css";
import { Link, NavLink } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../store/auth-context";

const Header = (props) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const { isLoggedIn } = useContext(AuthContext);

  const { t } = useTranslation();
  const navLinks = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Centers",
      link: "/centers",
    },
    {
      name: "Services",
      link: "/services",
    },
    {
      name: "Blog",
      link: "/articles",
    },
    {
      name: "About",
      link: "/statics",
    },
  ];

  const toggleMenuHandler = () => {
    if (!isMenuOpened) {
      setIsMenuOpened(true);
    } else {
      setIsMenuOpened(false);
    }
  };

  return (
    <>
      <nav className={isMenuOpened ? styles["active-nav"] : ""}>
        <div className={styles["menu-bars"]} onClick={toggleMenuHandler}>
          <span className="material-symbols-outlined">
            {isMenuOpened ? "close" : "menu"}
          </span>
        </div>
        <ul className={styles.navigation}>
          <li>
            <img src={logo} width="66px" height="67px" alt="Salonate logo" />
          </li>
          {navLinks.map((navLink) => (
            <li key={navLink.name}>
              <NavLink
                className={(navData) =>
                  navData.isActive ? styles["active-nav"] : ""
                }
                to={navLink.link}
              >
                {t(navLink.name)}
              </NavLink>
            </li>
          ))}
        </ul>
        <ul className={styles.extraLinks}>
          <li>
            <span className="material-symbols-outlined">search</span>
          </li>
          <li onClick={props.onChangeLanguage}>{t("Language")}</li>
          {isLoggedIn && (
            <>
              {/* <li>
            <span className="material-symbols-outlined">notifications</span>
          </li> */}
              <li>
                <Link to="/order-making/second">
                  <span className="material-symbols-outlined">
                    shopping_cart
                  </span>
                </Link>
              </li>
            </>
          )}
          <li>
            {isLoggedIn && (
              <Link to="/profile">
                <span className="material-symbols-outlined white">person</span>
              </Link>
            )}
            {!isLoggedIn && (
              <>
                <Link to="/auth">{t("Login")}</Link> /{" "}
                <Link to="/auth/signup">{t("Create account")}</Link>
              </>
            )}
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
