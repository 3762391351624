import { domain } from "./authApis";

export const paymentPrepare = async ({ orderId }) => {
  let params = "?";

  if (orderId) {
    params = params + `order_id=${orderId}`;
  }

  const response = await fetch(domain + "/Payment/preparePayment" + params);

  return response.url;
};
