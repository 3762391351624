import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Button from "../UI/Button";

import Input from "../UI/Input";

import classes from "./ApplySalonForm.module.css";
import useHttp from "../../hooks/use-http";
import { applyStore } from "../../lib/authApis";

const ApplySalonForm = () => {
  const { t } = useTranslation();

  const { sendRequest, status, error } = useHttp(applyStore);

  const enNameInputRef = useRef();
  const arNameInputRef = useRef();
  const enAboutInputRef = useRef();
  const arAboutInputRef = useRef();
  const enAddressInputRef = useRef();
  const arAddressInputRef = useRef();
  const emailInputRef = useRef();
  const passInputRef = useRef();

  const applySalonHandler = (e) => {
    e.preventDefault();

    const enNameValue = enNameInputRef.current.value;
    const arNameValue = arNameInputRef.current.value;
    const enAboutValue = enAboutInputRef.current.value;
    const arAboutValue = arAboutInputRef.current.value;
    const enAddressValue = enAddressInputRef.current.value;
    const arAddressValue = arAddressInputRef.current.value;
    const emailValue = emailInputRef.current.value;
    const passValue = passInputRef.current.value;

    const reqBody = {
      email: emailValue,
      password: passValue,
      name_ar: arNameValue,
      name_en: enNameValue,
      about_ar: arAboutValue,
      about_en: enAboutValue,
      address_ar: arAddressValue,
      address_en: enAddressValue,
    };

    // console.log(reqBody);

    sendRequest({ reqBody });
  };

  useEffect(() => {
    if (status === "pending" && !error) {
      alert("Your Request has successfully arrived to the owner");
    }
    // eslint-disable-next-line
  }, [status]);

  return (
    <form className={classes.form} onSubmit={applySalonHandler}>
      <h1>{t("Join Us")}</h1>
      <fieldset className={classes.fieldset}>
        <legend>Salon Name</legend>
        <Input
          ref={arNameInputRef}
          type="text"
          id={"salon_name_ar"}
          name={"salon_name_ar"}
          required={true}
        >
          {t("Arabic")}
        </Input>
        <Input
          ref={enNameInputRef}
          type="text"
          id={"salon_name_en"}
          name={"salon_name_en"}
          required={true}
        >
          {t("English")}
        </Input>
      </fieldset>
      <fieldset className={classes.fieldset}>
        <legend>About Salon</legend>
        <Input
          ref={arAboutInputRef}
          type="text"
          id={"salon_about_ar"}
          name={"salon_about_ar"}
          required={true}
        >
          {t("Arabic")}
        </Input>
        <Input
          ref={enAboutInputRef}
          type="text"
          id={"salon_about_en"}
          name={"salon_about_en"}
          required={true}
        >
          {t("English")}
        </Input>
      </fieldset>
      <fieldset className={classes.fieldset}>
        <legend>Salon Address</legend>
        <Input
          ref={arAddressInputRef}
          type="text"
          id={"salon_address_ar"}
          name={"salon_address_ar"}
          required={true}
        >
          {t("Arabic")}
        </Input>
        <Input
          ref={enAddressInputRef}
          type="text"
          id={"salon_address_en"}
          name={"salon_address_en"}
          required={true}
        >
          {t("English")}
        </Input>
      </fieldset>
      <fieldset>
        <Input ref={emailInputRef} type="email" name="email">
          {t("Email")}
        </Input>
        <Input ref={passInputRef} type="password" name="password">
          {t("Password")}
        </Input>
      </fieldset>
      {status === "pending" && (
        <Button className="btn-disabled">{t("Pending...")}</Button>
      )}
      {!(status && status === "pending") && (
        <Button type="submit">{t("Join Us")}</Button>
      )}
    </form>
  );
};

export default ApplySalonForm;
