import { createContext, useState } from "react";

const AuthContext = createContext({
  lang: "",
  token: "",
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
  changeLang: (lang) => {},
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("token");
  const initialLang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "ar";
  const [token, setToken] = useState(initialToken);
  const [lang, setLang] = useState(initialLang);

  const isLoggedIn = !!token;

  const loginHandler = (idToken) => {
    setToken(idToken);
    localStorage.setItem("token", idToken);
  };

  const logoutHandler = () => {
    setToken(null);
    localStorage.removeItem("token");
  };

  const changeLang = (language) => {
    setLang(language);
    localStorage.setItem("language", language);
  };

  const contextValue = {
    lang: lang,
    token,
    isLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    changeLang,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
