import { createContext, useState } from "react";

const CartContext = createContext({
  product: {},
  addons: [{ name: "", id: "", price: "" }],
  checkedAddons: [],
  price: 0,
  addItem: (item, price) => {},
  removeItem: (item) => {},
  addAdd: (addon) => {},
  removeAdd: (addonId) => {},
  checkAddons: (addonId, price) => {},
  uncheckAddons: (addonId, price) => {},
});

export const CartContextProvider = (props) => {
  const [product, setProduct] = useState({});
  const [addons, setAddons] = useState([]);
  const [checkedAddons, setCheckedAddons] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(0);

  const addItemHandler = (item, price) => {
    setProduct(item);
    setCurrentPrice(price);
    setAddons([]);
    setCheckedAddons([]);
  };

  const removeItemHandler = (item) => {
    setProduct({});
  };

  const addAddHandler = (addon) => {
    setAddons((prevState) => {
      prevState.push({ ...addon, checked: false });
      return prevState;
    });
  };

  const removeAddHandler = (addonId) => {
    setAddons((prevState) => prevState.filter((addon) => addon.id !== addonId));
  };

  const checkAddonHandler = (addonId, price) => {
    setCheckedAddons((prevState) => {
      prevState.push(addonId);
      return prevState;
    });
    setCurrentPrice((prevState) => prevState + price);
  };

  const uncheckAddonHandler = (addonId, price) => {
    setCheckedAddons((prevState) =>
      prevState.filter((addon) => addon !== addonId)
    );
    setCurrentPrice((prevState) => prevState - price);
  };

  const contextValue = {
    product: product,
    addons,
    checkedAddons: checkedAddons,
    price: currentPrice,
    addItem: addItemHandler,
    removeItem: removeItemHandler,
    addAdd: addAddHandler,
    removeAdd: removeAddHandler,
    checkAddons: checkAddonHandler,
    uncheckAddons: uncheckAddonHandler,
  };

  return (
    <CartContext.Provider value={contextValue}>
      {props.children}
    </CartContext.Provider>
  );
};

export default CartContext;
