import { useEffect } from "react";
import styles from "./Footer.module.css";

import logo from "../../assets/logo-min.png";
import fbIcon from "../../assets/facebook-min.png";
import linkinIcon from "../../assets/linked in-min.png";
import twitterIcon from "../../assets/twitter-min.png";
import utubeIcon from "../../assets/utube-min.png";

import { useTranslation } from "react-i18next";
import useHttp from "../../hooks/use-http";
import { getAppInfo } from "../../lib/categoriesStaticsApis";
import { Link } from "react-router-dom";

const Footer = () => {
  const socials = [
    {
      discrip: "Facebook icon",
      icon: fbIcon,
      link: "#",
    },
    {
      discrip: "Twitter icon",
      icon: twitterIcon,
      link: "#",
    },
    {
      discrip: "Linked icon",
      icon: linkinIcon,
      link: "#",
    },
    {
      discrip: "Youtube icon",
      icon: utubeIcon,
      link: "#",
    },
  ];

  const {
    sendRequest,
    status,
    data: appInfo,
    error,
  } = useHttp(getAppInfo, true);

  useEffect(() => {
    sendRequest();
  }, [sendRequest]);

  const { t } = useTranslation();
  return (
    <footer>
      <div className={styles["logo-container"]}>
        <img
          src={logo}
          width="68.22px"
          height="68.82px"
          alt="Salonaty logo"
        ></img>
      </div>
      <div className={styles["footer-content"]}>
        <div>
          <h2>{t("About")}</h2>
          <p>
            هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة لقد تم توليد هذا
            النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد
            من النصوص الأخرى
          </p>
        </div>
        <div>
          <h2>
            <Link to="/">{t("Home")}</Link>
          </h2>
          <ul>
            <li>
              <Link to="/centers">{t("Centers")}</Link>
            </li>
            <li>
              <Link to="/services">{t("Services")}</Link>
            </li>
            <li>
              <Link to="/articles">{t("Blog")}</Link>
            </li>
            <li>
              <Link to="/statics">{t("About")}</Link>
            </li>
          </ul>
        </div>
        <div>
          <h2>{t("Call")}</h2>
          <ul>
            <li>
              {status === "completed" && !error
                ? appInfo.data.phone
                : t("Loading...")}
            </li>
            <li>
              {status === "completed" && !error
                ? appInfo.data.email
                : t("Loading...")}
            </li>
            <li>
              <Link to="/statics/contact-us">{t("Contact")}</Link>
            </li>
          </ul>
        </div>
        <div className={styles["social-links"]}>
          <ul>
            {socials.map((social) => (
              <li key={social.discrip}>
                <a href={social.link}>
                  <img
                    src={social.icon}
                    alt={social.discrip}
                    width="40px"
                    height="40px"
                  ></img>
                </a>
              </li>
            ))}
          </ul>
          <p>جميع الحقوق محفوظة 2022 لشركة صالوناتى</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
