import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import arabic from "./translation/ar.json";
import english from "./translation/en.json";

const resources = {
  en: {
    translation: english,
  },
  ar: {
    translation: arabic,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ar",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
