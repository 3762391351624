import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useHttp from "../../../hooks/use-http";
import { paymentPrepare } from "../../../lib/paymentPrepareApis";

const PaymentPage = () => {
  const params = useParams();

  const {
    sendRequest: paymentReq,
    status: paymentReqStatus,
    error: paymentReqError,
    data: paymentReqData,
  } = useHttp(paymentPrepare, true);

  useEffect(() => {
    paymentReq({ orderId: params.orderId });
  }, []);

  return (
    <div>
      {paymentReqStatus === "completed" &&
        !paymentReqError &&
        // window.open(paymentReqData, "/blank")
        console.log(paymentReqData)}
    </div>
  );
};

export default PaymentPage;
