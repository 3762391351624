import ApplySalonForm from "../components/applyStore/ApplySalonForm";
import Card from "../components/UI/Card";

const ApplySalon = () => {
  return (
    <Card>
      <ApplySalonForm />
    </Card>
  );
};

export default ApplySalon;
