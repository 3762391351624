import { useContext, useEffect } from "react";

import i18n from "i18next";

import Header from "./Header";
import Footer from "./Footer";
import AuthContext from "../../store/auth-context";
import useHttp from "../../hooks/use-http";
import { postChangeLanguage } from "../../lib/authApis";

const Layout = (props) => {
  const {
    lang: language,
    changeLang: setLanguage,
    token,
    isLoggedIn,
  } = useContext(AuthContext);

  const { sendRequest: changeLangReq } = useHttp(postChangeLanguage);

  const overlays = document.getElementById("overlays");
  overlays.setAttribute("dir", "rtl");

  useEffect(() => {
    if (language === "en") {
      i18n.changeLanguage("en");
      setLanguage("en");
      overlays.setAttribute("dir", "ltr");
    }
  }, [overlays, language, setLanguage]);

  const changeLanguage = () => {
    if (language === "en") {
      setLanguage("ar");
      if (isLoggedIn) {
        changeLangReq({ lang: "ar", token });
      }
      i18n.changeLanguage("ar");
      overlays.setAttribute("dir", "rtl");
    } else {
      i18n.changeLanguage("en");
      setLanguage("en");
      if (isLoggedIn) {
        changeLangReq({ lang: "en", token });
      }
      overlays.setAttribute("dir", "ltr");
    }
  };

  return (
    <div dir={language === "ar" ? "rtl" : "ltr"}>
      <Header onChangeLanguage={changeLanguage} />
      <main>{props.children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
