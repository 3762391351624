import styles from "./Card.module.css";
const Card = (props) => {
  return (
    <div
      className={`${styles.card} ${props.className ? props.className : ""}`}
      dir={props.dir ? props.dir : ""}
    >
      {props.children}
    </div>
  );
};

export default Card;
