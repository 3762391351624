export const domain = "https://api.salonate.com/api";

export const getProfileData = async (token) => {
  const response = await fetch(`${domain}/Auth_private/my_info`, {
    method: "GET",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not get Profile data.");
  }

  return data;
};

export const accountRegister = async ({ userData, lang }) => {
  const formData = new FormData();

  for (const prop in userData) {
    if (Object.hasOwnProperty.call(userData, prop)) {
      const element = userData[prop];
      formData.append(prop, element);
    }
  }
  const response = await fetch(`${domain}/Auth_general/register`, {
    method: "POST",
    headers: { lang },
    body: formData,
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not create Account.");
  }

  return data;
};

export const accountLogin = async ({ userData, lang }) => {
  const formData = new FormData();

  for (const prop in userData) {
    if (Object.hasOwnProperty.call(userData, prop)) {
      const element = userData[prop];
      formData.append(prop, element);
    }
  }

  const response = await fetch(`${domain}/Auth_general/login`, {
    method: "POST",
    headers: { lang },
    body: formData,
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not enter Account.");
  }

  return data;
};

export const editProfile = async ({ profileData, token }) => {
  const formData = new FormData();
  for (const prop in profileData) {
    if (Object.hasOwnProperty.call(profileData, prop)) {
      const element = profileData[prop];
      formData.append(prop, element);
    }
  }

  const response = await fetch(`${domain}/Auth_private/edit_profile`, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: formData,
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not edit Profile.");
  }

  return data;
};

export const accountLogout = async (token) => {
  const response = await fetch(`${domain}/Auth_private/logout`, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not logout.");
  }

  return data;
};

export const sendActiveCode = async ({ activeCode, token }) => {
  const formData = new FormData();
  formData.append("code", activeCode);

  const response = await fetch(`${domain}/Auth_private/check_active_code`, {
    method: "POST",
    headers: { Authorization: "Bearer " + token },
    body: formData,
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not confirm mobile number.");
  }

  return data;
};

export const forgotPasswordReq = async (number) => {
  const formData = new FormData();
  formData.append("phone", number);

  const response = await fetch(`${domain}/Auth_general/forget_password`, {
    method: "POST",
    body: formData,
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not check your data.");
  }

  return data;
};

export const checkPasswordCode = async ({ activeCode, token }) => {
  const formData = new FormData();

  formData.append("code", activeCode);

  const response = await fetch(`${domain}/Auth_private/check_password_code`, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: formData,
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not confirm your number.");
  }

  return data;
};

export const postResetPassword = async ({ reqData, token }) => {
  const formData = new FormData();
  for (const prop in reqData) {
    if (Object.hasOwnProperty.call(reqData, prop)) {
      const element = reqData[prop];
      formData.append(prop, element);
    }
  }

  const response = await fetch(`${domain}/Auth_private/reset_password`, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: formData,
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not reset your password.");
  }

  return data;
};

export const postChangePassword = async ({ reqData, token }) => {
  const formData = new FormData();
  for (const prop in reqData) {
    if (Object.hasOwnProperty.call(reqData, prop)) {
      const element = reqData[prop];
      formData.append(prop, element);
    }
  }

  const response = await fetch(`${domain}/Auth_private/change_password`, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: formData,
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not change your password.");
  }

  return data;
};

export const postResendCode = async ({ check, token }) => {
  const response = await fetch(`${domain}/Auth_private/resend_code`, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: JSON.stringify(check),
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not send the request.");
  }

  return data;
};

export const postChangeLanguage = async ({ lang, token }) => {
  const formData = new FormData();
  formData.append("lang", lang);

  const response = await fetch(`${domain}/Auth_private/change_lang`, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: formData,
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not change the language.");
  }

  return data;
};

export const applyStore = async ({ reqBody }) => {
  const formData = new FormData();

  for (const prop in reqBody) {
    if (Object.hasOwnProperty.call(reqBody, prop)) {
      const element = reqBody[prop];
      formData.append(prop, element);
    }
  }

  const response = await fetch(`${domain}/Auth_general/applyStore`, {
    method: "POST",
    body: formData,
  });

  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not change the language.");
  }

  return data;
};
