import React, { Suspense, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import AuthContext from "./store/auth-context";

import "./App.css";
import "./fonts/PFDinTextUniversal.ttf";
import "./fonts/PFDinTextUniversalMedium.ttf";
import PaymentPage from "./components/order-making/payment/PaymentPage";
import ApplySalon from "./pages/ApplySalon";
// import Auth from "./components/Auth/Auth";

const Home = React.lazy(() => import("./components/Home/Home"));
const Auth = React.lazy(() => import("./components/Auth/Auth"));

const AllCategories = React.lazy(() => import("./pages/AllCategories"));
const AllCenters = React.lazy(() => import("./pages/AllCenters"));
const AllServices = React.lazy(() => import("./pages/AllServices"));
const AllArticles = React.lazy(() => import("./pages/AllArticles"));
const Statics = React.lazy(() => import("./pages/Statics"));
const Profile = React.lazy(() => import("./pages/Profile"));
const CenterView = React.lazy(() => import("./pages/CenterView"));
const ServiceView = React.lazy(() => import("./pages/ServiceView"));
const ViewArticle = React.lazy(() => import("./pages/ViewArticle"));
const OrderMaking = React.lazy(() => import("./pages/OrderMaking"));

function App() {
  const { isLoggedIn } = useContext(AuthContext);

  // const location = useLocation();

  return (
    <Layout>
      <Suspense fallback={<h1>Loading...</h1>}>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="/auth/*" element={<Auth />} />
          </Route>
          <Route path="/categories" element={<AllCategories />} />
          <Route path="/categories/:category_id" element={<AllServices />} />
          <Route path="/centers" element={<AllCenters />} />
          <Route
            path="/centers/:center_id/*"
            element={<CenterView />}
            // element={
            //   isLoggedIn ? (
            //     <CenterView />
            //   ) : (
            //     <Navigate replace="true" to="/auth" />
            //   )
            // }
          />
          <Route path="/services" element={<AllServices />} />
          <Route
            path="/services/:service_id/*"
            element={
              isLoggedIn ? (
                <ServiceView />
              ) : (
                <Navigate replace="true" to="/auth" />
              )
            }
          />
          <Route
            path="/order-making/*"
            element={
              isLoggedIn ? (
                <OrderMaking />
              ) : (
                <Navigate replace="true" to="/auth" />
              )
            }
          />
          <Route path="/articles/*" element={<AllArticles />} />
          <Route path="/articles/:article_id" element={<ViewArticle />} />
          <Route
            path="/profile/*"
            element={
              isLoggedIn ? <Profile /> : <Navigate replace="true" to="/auth" />
            }
          />
          <Route path="/statics/*" element={<Statics />} />
          <Route
            path="/statics"
            element={<Navigate replace="true" to="/statics/about-us" />}
          />
          <Route path="/order-payment/:orderId" element={<PaymentPage />} />
          <Route path="/apply_salon" element={<ApplySalon />} />
          <Route path="*" element={<h1>404 Page not found</h1>} />
        </Routes>
      </Suspense>
    </Layout>
  );
}

export default App;
