import React, { useEffect, useState } from "react";
import classes from "./Input.module.css";

const Input = React.forwardRef((props, ref) => {
  const [isFloated, setIsFloated] = useState(false);

  const [isPhoneInput, setIsPhoneInput] = useState(false);

  const [isPassword, setIsPassword] = useState(false);
  const [isPassShown, setIsPassShown] = useState(false);

  useEffect(() => {
    if (props.floated === true) {
      setIsFloated(true);
    }

    if (props.type === "password") {
      setIsPassword(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.type === "tel") {
      setIsPhoneInput(true);
    }
  }, [props.type]);

  const floatLabelHandler = (e) => {
    e.preventDefault();
    setIsFloated(true);
  };

  const normalLabelHandler = (e) => {
    e.preventDefault();
    if (!e.target.value) {
      setIsFloated(false);
    }
  };

  const showPasswordHandler = (event) => {
    event.preventDefault();

    setIsPassShown((prevState) => !prevState);
  };

  const phoneInputHandler = (event) => {
    event.preventDefault();
    props.onChange();
  };

  return (
    <div className={classes.input}>
      <label htmlFor={props.id} className={isFloated ? classes.float : ""}>
        <span className="material-symbols-outlined">{props.icon}</span>
        {props.children}
      </label>
      <input
        ref={ref}
        type={isPassShown ? "text" : props.type}
        placeholder={props.placeholder}
        id={props.id}
        name={props.name}
        onFocus={floatLabelHandler}
        onBlur={normalLabelHandler}
        required={props.required}
        onChange={isPhoneInput ? phoneInputHandler : null}
      />
      {isPassword && (
        <span className={classes.visibility} onClick={showPasswordHandler}>
          <span className="material-symbols-outlined">
            {isPassShown ? "visibility" : "visibility_off"}
          </span>
        </span>
      )}

      {props.extraElement && props.extraElement}
    </div>
  );
});

export default Input;
