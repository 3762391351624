import { domain } from "./authApis";

export const getCategories = async (lang) => {
  const response = await fetch(`${domain}/General/categories`, {
    headers: {
      lang,
    },
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not get Profile data.");
  }

  return data;
};

export const getSliders = async () => {
  const response = await fetch(`${domain}/General/sliders`);
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not get Profile data.");
  }

  return data;
};

export const getFaqs = async () => {
  const response = await fetch(`${domain}/General/Faqs`);
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not get Profile data.");
  }

  return data;
};

export const getAppInfo = async (lang) => {
  const response = await fetch(`${domain}/General/appInfo`, {
    headers: {
      lang,
    },
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not get Profile data.");
  }

  return data;
};

export const postContactUs = async (reqData) => {
  //email --- name --- message --- phone
  const formData = new FormData();
  for (const prop in reqData) {
    if (Object.hasOwnProperty.call(reqData, prop)) {
      const element = reqData[prop];
      formData.append(prop, element);
    }
  }

  const response = await fetch(`${domain}/General/contactUs`, {
    method: "POST",
    body: formData,
  });
  const data = await response.json();

  if (!response.ok || !data.status) {
    throw new Error(data.message || "Could not get Profile data.");
  }

  return data;
};
